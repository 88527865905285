import axios from 'axios';

const getters = {};

const SET_SMS = 'SET_SMS';
const SET_SETTINGS = 'SET_SETTINGS';

const mutations = {
    [SET_SMS](state, data) {
        state.sms = data;
    },

    [SET_SETTINGS](state, data) {
        state.settings = data;
    },
};

export const loadList = 'loadList';

const actions = {
    getSettings({ commit }) {
        return axios.get('/api/sms/settings').then((response) => {
            commit(SET_SETTINGS, JSON.parse(JSON.stringify(response.data)));
        });
    },
};

const state = {
    sms: {},
    settings: {},
};

export const sms = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
