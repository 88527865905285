/**
 * Merge an updated version of an array into the existing array.
 * The updated version of the array may only contain the items that where updated.
 * In other words, this function supports both merging partial updates and full updates.
 *
 * @param {Array} currentArray current vuex array
 * @param {Array} arrayDiff diff objects which is to be applied to the current array
 * @param {any} [compareKey='id'] key on which objects are compared
 *
 * @return {Array} merged array
 */
export function mergeArray(currentArray, arrayDiff, compareKey = 'id') {
    let mergedArray = [...currentArray];

    for (const objectDiff of arrayDiff) {
        const foundIndex = mergedArray.findIndex(object => object[compareKey] === objectDiff[compareKey]);

        // If item on the given merge key exist, merge diff into existing object
        if (foundIndex > -1) {
            const currentObject = mergedArray[foundIndex];
            mergedArray[foundIndex] = { ...currentObject, ...objectDiff };

        // Else append diff as a new object at the end of array
        } else {
            mergedArray.push(objectDiff);
        }
    }

    return mergedArray;
}

/**
 * Merge an updated version of an object with an existing one.
 * The updated version may only contain the properties that where updated.
 * In other words, this function supports both merging partial updates and full updates.
 *
 * @param {Object} currentObject current vuex object
 * @param {Object} objectDiff diff which is going to be applied to the current object
 *
 * @return {Object} merged object
 */
export function mergeObject(currentObject, objectDiff) {
    return {
        ...currentObject,
        ...objectDiff,
    };
}
