import FuzzySet from 'fuzzyset.js';
import { createNamespacedHelpers } from 'vuex';
import { mergeArray, mergeObject } from '../../../libs/vuex-merge';
import { get, put, post } from '../../../libs/kk-api';
import { getValue, setValue } from '../../../libs/storage';

export const namespace = 'infotech';
const { mapActions, mapState, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

//
// State
//
function initialState() {
    return {
        infotechIntegration: {
            self: null,
            api_key: null,
            is_connected: false,
            suggest_hour_registrations: false,
        },
        employees: [],
        projects: [],
        infotechProjects: [],
        infotechCards: [],

        loading: {
            apiKey: false,
            employees: false,
            projects: false,
            infotechProjects: false,
            infotechCards: false,
            infotechIntegration: false,
            infotechIntegrationConnecting: false,
            suggestHourRegistrations: false,
        },

        filter: {
            infotechCardCompanies: [],
            showOnlyFilled: false,
            require100Percent: false,
            visible: false,
            hideExpiredCards: false,
        },
    };
}

//
// Mutations
//
const LOADING = 'LOADING';
const FILTER = 'FILTER';
const PROJECTS = 'PROJECTS';
const EMPLOYEES = 'EMPLOYEES';
const INFOTECH_CARDS = 'INFOTECH_CARDS';
const INFOTECH_PROJECTS = 'INFOTECH_PROJECTS';
const INFOTECH_INTEGRATION = 'INFOTECH_INTEGRATION';

const mutations = {
    LOADING: (state, diff) => {
        state.loading = mergeObject(state.loading, diff);
    },
    INFOTECH_INTEGRATION: (state, diff) => {
        state.infotechIntegration = mergeObject(state.infotechIntegration, diff);
    },
    EMPLOYEES: (state, diff) => {
        state.employees = mergeArray(state.employees, diff);
    },
    PROJECTS: (state, diff) => {
        state.projects = mergeArray(state.projects, diff);
    },
    INFOTECH_CARDS: (state, diff) => {
        state.infotechCards = mergeArray(state.infotechCards, diff, 'external_id');
    },
    INFOTECH_PROJECTS: (state, diff) => {
        state.infotechProjects = mergeArray(state.infotechProjects, diff, 'external_id');
    },
    FILTER: (state, diff) => {
        state.filter = mergeObject(state.filter, diff);
    },
    INFOTECH_PROJECTS_RESET: (state) => {
        state.infotechProjects = [];
    },
    INFOTECH_CARDS_RESET: (state) => {
        state.infotechCards = [];
    },
};

//
// Getters
//
const getters = {
    isFilterActive: (state) => {
        return state.filter.infotechCardCompanies.length > 0
            || state.filter.showOnlyFilled
            || state.filter.require100Percent;
    },

    infotechIntegrationExists: state => state.infotechIntegration.self !== null,

    infotechCardCompanies: (state) => {
        const companies = new Set();

        for (const { organization_name } of state.infotechCards) {
            if (organization_name !== null) {
                companies.add(organization_name);
            }
        }

        const sortedCompanies = [...companies].sort((a, b) => a.localeCompare(b));

        return sortedCompanies;
    },

    unlinkedInfotechCardsEmployees: ({ employees, infotechCards, 'filter': { infotechCardCompanies, showOnlyFilled, require100Percent, hideExpiredCards } }, { fuzzyEmployeesSet }) => {
        const unlinkedInfotechCardsEmployees = [];

        for (const infotech_card of infotechCards) {
            // Filter
            if (infotech_card.employee !== null) {
                continue;
            }

            // Filter
            if (infotechCardCompanies.length !== 0 && (!infotechCardCompanies.includes(infotech_card.organization_name))) {
                continue;
            }

            // Filter
            if (hideExpiredCards && infotech_card.expires_at !== null) {
                const expirationDate = moment(infotech_card.expires_at).startOf('day');
                const now = moment().startOf('day');
                const diff = expirationDate.diff(now);

                if (diff < 0) {
                    continue;
                }
            }

            const fuzzy = fuzzyEmployeesSet.get(infotech_card.name);

            //
            // If the fuzzy is null, set employee to null
            //
            if (fuzzy === null) {
                pushUnfilled(infotech_card);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1];

            if (require100Percent && fuzzyScore !== 1) {
                pushUnfilled(infotech_card);
                continue;
            }

            //
            // If fuzzy score is less than 70%, filter away
            //
            if (fuzzyScore < 0.70) {
                pushUnfilled(infotech_card);
                continue;
            }

            const employee = employees.find(({ name }) => name === fuzzyName);

            if (employee === undefined) {
                throw 'Fatal error in unlinkedInfotechCardsEmployees getter: Employee should not be undefined';
            }

            //
            // If the first letter is not identical, ignore
            //
            if (fuzzyScore <= 0.99 && infotech_card.first_name[0].toLowerCase() !== employee.first_name[0].toLowerCase()) {
                pushUnfilled(infotech_card);
                continue;
            }

            if (fuzzyScore <= 0.99 && infotech_card.last_name[0].toLowerCase() !== employee.last_name[0].toLowerCase()) {
                pushUnfilled(infotech_card);
                continue;
            }

            unlinkedInfotechCardsEmployees.push({
                infotech_card,
                employee,
                fuzzyScore,
                fuzzyName,
            });
        }

        unlinkedInfotechCardsEmployees.sort((a, b) => a.infotech_card.name.localeCompare(b.infotech_card.name));

        return unlinkedInfotechCardsEmployees;

        function pushUnfilled(infotech_card) {
            if (showOnlyFilled) {
                return;
            }
            unlinkedInfotechCardsEmployees.push({
                infotech_card,
                employee: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    unlinkedInfotechProjectsProjects: ({ projects, infotechProjects, 'filter': { showOnlyFilled, require100Percent } }, { fuzzyProjectsSet }) => {
        const unlinkedInfotechProjectsProjects = [];

        for (const infotech_project of infotechProjects) {
            // Filter
            if (infotech_project.project !== null) {
                continue;
            }

            const fuzzy = fuzzyProjectsSet.get(infotech_project.name);

            if (fuzzy === null) {
                pushUnfilled(infotech_project);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1];

            if (require100Percent && fuzzyScore !== 1) {
                continue;
            }

            //
            // If fuzzy score is less than X%, ignore
            //
            if (Math.floor(fuzzyScore * 100) < 75) {
                pushUnfilled(infotech_project);
                continue;
            }

            const project = projects.find(({ name }) => name === fuzzyName);

            if (project === undefined) {
                throw 'Fatal error in unlinkedInfotechProjectsProjects getter: project should not be undefined';
            }

            //
            // If the first letter is not identical, ignore
            //
            if (project.name.slice(0, 1).toLowerCase() !== infotech_project.name.slice(0, 1).toLowerCase()) {
                pushUnfilled(infotech_project);
                continue;
            }

            unlinkedInfotechProjectsProjects.push({
                infotech_project,
                project,
                fuzzyScore,
                fuzzyName,
            });
        }

        unlinkedInfotechProjectsProjects.sort((a, b) => a.infotech_project.name.localeCompare(b.infotech_project.name));

        return unlinkedInfotechProjectsProjects;

        function pushUnfilled(infotech_project) {
            if (showOnlyFilled || require100Percent) {
                return;
            }
            unlinkedInfotechProjectsProjects.push({
                infotech_project,
                project: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    fuzzyEmployeesSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.employees) {
            fuzzySet.add(name);
        }

        return fuzzySet;
    },

    fuzzyProjectsSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.projects) {
            fuzzySet.add(name);
        }

        return fuzzySet;
    },

    linkedInfotechCards: ({ infotechCards }) => {
        const filtered = infotechCards.filter(({ employee }) => employee !== null);

        return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },

    linkedInfotechProjects: ({ infotechProjects }) => {
        const filtered = infotechProjects.filter(({ project }) => project !== null);

        return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },

    employees: ({ employees }) => {
        return [...employees].sort((a, b) => a.name.localeCompare(b.name));
    },

    projects: ({ projects }) => {
        return [...projects].sort((a, b) => a.name.localeCompare(b.name));
    },
};

//
// Actions
//
const actions = {
    /**
     * Load the ifotech module
     */
    loadInfotech: async ({ commit }, user) => {
        commit(LOADING, { infotechIntegration: true });

        try {
            const infotechIntegrations = await get('/infotech_integrations');
            commit(INFOTECH_INTEGRATION, infotechIntegrations[0]);
        } finally {
            commit(LOADING, { infotechIntegration: false });
        }

        const filter = await getValue(FILTER, user.id, { namespace, defaultValue: {} });
        commit(FILTER, filter);
    },

    loadEmployees: async ({ commit }) => {
        commit(LOADING, { employees: true });

        try {
            const employees = await get('/employees');
            commit(EMPLOYEES, employees);
        } finally {
            commit(LOADING, { employees: false });
        }
    },

    loadProjects: async ({ commit }) => {
        commit(LOADING, { projects: true });

        try {
            let projects = await get('/projects');

            // Add key numberName to all projects: "3 | my project". Used for label on select
            if (Array.isArray(projects)) {
                projects = projects.map((project) => {
                    if (project.project_no !== null && project.project_no !== '') {
                        project.numberName = `${project.project_no} | ${project.name}`;
                    } else {
                        project.numberName = project.name;
                    }

                    return project;
                });
            }

            commit(PROJECTS, projects);
        } finally {
            commit(LOADING, { projects: false });
        }
    },

    loadInfotechProjects: async ({ commit }) => {
        commit(LOADING, { infotechProjects: true });

        try {
            const infotechProjects = await get('/infotech_projects');
            commit(INFOTECH_PROJECTS, infotechProjects);
        } finally {
            commit(LOADING, { infotechProjects: false });
        }
    },

    loadInfotechCards: async ({ commit }) => {
        commit(LOADING, { infotechCards: true });

        try {
            const infotechCards = await get('/infotech_cards');
            commit(INFOTECH_CARDS, infotechCards);
        } finally {
            commit(LOADING, { infotechCards: false });
        }
    },

    loadInfotechProjectsExternal: async ({ commit }) => {
        commit(LOADING, { infotechProjects: true });

        try {
            const infotechProjects = await get('/infotech_projects_external');
            commit(INFOTECH_PROJECTS, infotechProjects);
        } finally {
            commit(LOADING, { infotechProjects: false });
        }
    },

    loadInfotechCardsExternal: async ({ commit }) => {
        commit(LOADING, { infotechCards: true });

        try {
            const infotechCards = await get('/infotech_cards_external');
            commit(INFOTECH_CARDS, infotechCards);
        } finally {
            commit(LOADING, { infotechCards: false });
        }
    },

    updateInfotechIntegration: async ({ commit, state }, { payload, loadingFlag = null }) => {
        commit(LOADING, { infotechIntegration: true, [loadingFlag]: true });

        try {
            const infotechIntegration = await put(state.infotechIntegration.self, payload);
            commit(INFOTECH_INTEGRATION, infotechIntegration);
        } finally {
            commit(LOADING, { infotechIntegration: false, [loadingFlag]: false });
        }
    },

    connectInfotechIntegration: async ({ state, commit }) => {
        commit(LOADING, { infotechIntegrationConnecting: true });

        try {
            const infotechIntegration = await post(state.infotechIntegration.connect);
            commit(INFOTECH_INTEGRATION, infotechIntegration);
        } finally {
            commit(LOADING, { infotechIntegrationConnecting: false });
        }
    },

    disconnectInfotechIntegration: async ({ commit, state }) => {
        commit(LOADING, { infotechIntegrationConnecting: true });

        try {
            const infotechIntegration = await post(state.infotechIntegration.disconnect);
            commit(INFOTECH_INTEGRATION, infotechIntegration);
        } finally {
            commit(LOADING, { infotechIntegrationConnecting: false });
        }
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateInfotechProjects: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { infotechProjects: true });

        try {
            const updatedInfotechProjects = await put('/infotech_projects', payload);
            commit(INFOTECH_PROJECTS, updatedInfotechProjects);
        } finally {
            commit(LOADING, { infotechProjects: false });
        }
        dispatch('loadProjects');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].employee
     */
    updateInfotechCards: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { infotechCards: true });

        try {
            const updatedInfotechCards = await put('/infotech_cards', payload);
            commit(INFOTECH_CARDS, updatedInfotechCards);
        } finally {
            commit(LOADING, { infotechCards: false });
        }
        dispatch('loadEmployees');
    },

    onShowOnlyFilled: ({ dispatch, rootState }, showOnlyFilled) => {
        dispatch('updateFilter', {
            filter: { showOnlyFilled },
            user: rootState.user.user,
        });
    },

    onRequire100Percent({ dispatch, rootState }, require100Percent) {
        dispatch('updateFilter', {
            filter: { require100Percent },
            user: rootState.user.user,
        });
    },

    onHideExpiredCards: ({ dispatch, rootState }, hideExpiredCards) => {
        dispatch('updateFilter', {
            filter: { hideExpiredCards },
            user: rootState.user.user,
        });
    },

    onToggleFilterVisibility: ({ state, dispatch, rootState }) => {
        dispatch('updateFilter', {
            filter: { visible: !state.filter.visible },
            user: rootState.user.user,
        });
    },

    updateFilter: async ({ state, commit }, { filter, user }) => {
        commit(FILTER, filter);
        setValue(FILTER, state.filter, user.id, { namespace });
    },
};

export const {
    INFOTECH_CARDS_RESET,
    INFOTECH_PROJECTS_RESET,
} = mapMutations([
    'INFOTECH_CARDS_RESET',
    'INFOTECH_PROJECTS_RESET',
]);

export const {
    loading,
    infotechCards,
    infotechProjects,
    infotechIntegration,
    filter,
} = mapState([
    'filter',
    'loading',
    'infotechCards',
    'infotechProjects',
    'infotechIntegration',
]);

export const {
    infotechIntegrationExists,
    unlinkedInfotechCardsEmployees,
    unlinkedInfotechProjectsProjects,
    linkedInfotechCards,
    linkedInfotechProjects,
    infotechCardCompanies,
    isFilterActive,
    employees,
    projects,
} = mapGetters([
    'infotechIntegrationExists',
    'unlinkedInfotechCardsEmployees',
    'unlinkedInfotechProjectsProjects',
    'linkedInfotechProjects',
    'linkedInfotechCards',
    'infotechCardCompanies',
    'isFilterActive',
    'employees',
    'projects',
]);

export const {
    loadInfotech,
    loadEmployees,
    loadProjects,
    loadInfotechProjects,
    loadInfotechCards,
    loadInfotechProjectsExternal,
    loadInfotechCardsExternal,
    updateInfotechIntegration,
    connectInfotechIntegration,
    disconnectInfotechIntegration,
    updateInfotechProjects,
    updateInfotechCards,
    updateFilter,
    onShowOnlyFilled,
    onRequire100Percent,
    onToggleFilterVisibility,
    onHideExpiredCards,
} = mapActions([
    'loadInfotech',
    'loadEmployees',
    'loadProjects',
    'loadInfotechProjects',
    'loadInfotechCards',
    'loadInfotechProjectsExternal',
    'loadInfotechCardsExternal',
    'updateInfotechIntegration',
    'connectInfotechIntegration',
    'disconnectInfotechIntegration',
    'updateInfotechProjects',
    'updateInfotechCards',
    'updateFilter',
    'onShowOnlyFilled',
    'onRequire100Percent',
    'onToggleFilterVisibility',
    'onHideExpiredCards',
]);
//
// Export
//
export const store = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
