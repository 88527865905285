export const formattedChecklistTitle = (entry, industries) => {
    const industry = translatedIndustryName(
        industries.find(industry => industry.bransjeId === entry.bransjeId).navn,
    );

    if (entry.kategori) {
        return `[${industry}] ${entry.kategori} / ${entry.tittel}`;
    }

    return `[${industry}] ${entry.tittel}`;
};

export const translatedIndustryName = (name) => {
    let translation = trans('adminpanel.' + name);

    if (translation.includes('adminpanel.')) {
        translation = trans('shared.' + name);
    }

    if (translation.includes('shared.')) {
        translation = trans(name);
    }

    return translation;
};

export const formattedProcedureTitle = (entry) => {
    if (entry.group) {
        return `[${entry.group}] ${entry.name}`;
    }

    return entry.name;
};

export const toTitleCase = (str) => {
    return (str || '').replace(
        /\w\S*/g,
        text =>
            text.charAt(0).toUpperCase()
            + text.substring(1).toLowerCase(),
    );
};
