// Actions
export const updateCurrentSelectedDayDetails = 'updateCurrentSelectedDayDetails';

// Mutations
export const UPDATE_SELECTED_DAY = 'UPDATE_SELECTED_DAY';
export const SHOW_SELECTED_DAY = 'SHOW_SELECTED_DAY';
export const APPEND_ORDERS = 'APPEND_ORDERS';

const state = {
    currentSelectedDay: {},
    showSelectedDay: false,
    orders: {},
};

const mutations = {
    UPDATE_SELECTED_DAY: (state, day) => {
        state.currentSelectedDay = day;
    },
    SHOW_SELECTED_DAY: (state, bool) => {
        state.showSelectedDay = bool;

        if (bool === false) {
            state.currentSelectedDay = {};
        }
    },
    APPEND_ORDERS: (state, order) => {
        state.orders[order.id] = order;
    },
};

const actions = {
    updateCurrentSelectedDayDetails: ({ commit }, day) => {
        commit('UPDATE_SELECTED_DAY', day);
    },

};

export const weeklyRevision = {
    state,
    mutations,
    actions,
};
