import Vue from 'vue';
import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';

const TEAM_DEPARTMENT_URL = '/api/employee-team';
const POSITION_DEPARTMENT_URL = '/api/employee-position';

export const namespace = 'departments';
export const DEPARTMENT_STORE = {
    STATE: {
        LOADING: 'LOADING',
        TEAM_TYPES: 'TEAM_TYPES',
        POSITION_TYPES: 'POSITION_TYPES',
    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_DATA: 'SET_DATA',
    },
    ACTIONS: {
        FETCH_DEPARTMENTS: 'FETCH_DEPARTMENTS',
        CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
        UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
        DELETE_DEPARTMENT: 'DELETE_DEPARTMENT',
    },
    LOADING: {
        FETCHING_DEPARTMENTS: 'FETCHING_DEPARTMENTS',
        CREATING_DEPARTMENT: 'CREATING_DEPARTMENT',
        UPDATING_DEPARTMENT: 'UPDATING_DEPARTMENT',
        DELETING_DEPARTMENT: 'DELETING_DEPARTMENT',
    },
};

function initialState() {
    return {
        [DEPARTMENT_STORE.STATE.LOADING]: {
            [DEPARTMENT_STORE.LOADING.FETCHING_DEPARTMENTS]: false,
            [DEPARTMENT_STORE.LOADING.CREATING_DEPARTMENT]: false,
            [DEPARTMENT_STORE.LOADING.UPDATING_DEPARTMENT]: false,
            [DEPARTMENT_STORE.LOADING.DELETING_DEPARTMENT]: false,
        },
        [DEPARTMENT_STORE.STATE.TEAM_TYPES]: [],
        [DEPARTMENT_STORE.STATE.POSITION_TYPES]: [],
    };
}

function sortedByTitle(items) {
    return items.sort((a, b) => a.title.localeCompare(b.title, 'no', { numeric: true, sensitivity: 'base' }));
}

function typeToUrl(type) {
    switch (type) {
        case 'teams':
            return TEAM_DEPARTMENT_URL;
        case 'positions':
            return POSITION_DEPARTMENT_URL;
        default:
            throw new Error(`Unknown type: ${type}`);
    }
}

function typeToStateKey(type) {
    switch (type) {
        case 'teams':
            return DEPARTMENT_STORE.STATE.TEAM_TYPES;
        case 'positions':
            return DEPARTMENT_STORE.STATE.POSITION_TYPES;
        default:
            throw new Error(`Unknown type: ${type}`);
    }
}

const actions = {
    [DEPARTMENT_STORE.ACTIONS.FETCH_DEPARTMENTS]: async ({ commit, state }) => {
        if (state[DEPARTMENT_STORE.LOADING.FETCHING_DEPARTMENTS]) {
            return;
        }

        commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
            key: DEPARTMENT_STORE.LOADING.FETCHING_DEPARTMENTS,
            value: true,
        });

        let url;

        try {
            url = `${TEAM_DEPARTMENT_URL}`;
            const fetchTeams = await axios.get(url);
            commit(DEPARTMENT_STORE.MUTATIONS.SET_DATA, {
                key: DEPARTMENT_STORE.STATE.TEAM_TYPES,
                value: sortedByTitle(fetchTeams.data),
            });

            url = `${POSITION_DEPARTMENT_URL}`;
            const fetchPositions = await axios.get(url);
            commit(DEPARTMENT_STORE.MUTATIONS.SET_DATA, {
                key: DEPARTMENT_STORE.STATE.POSITION_TYPES,
                value: sortedByTitle(fetchPositions.data),
            });
        } catch (err) {
            logThrowApiError(err, url);
        } finally {
            commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
                key: DEPARTMENT_STORE.LOADING.FETCHING_DEPARTMENTS,
                value: false,
            });
        }
    },

    [DEPARTMENT_STORE.ACTIONS.CREATE_DEPARTMENT]: async ({ commit, state }, { type, title, users }) => {
        if (state[DEPARTMENT_STORE.LOADING.CREATING_DEPARTMENT]) {
            return;
        }

        commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
            key: DEPARTMENT_STORE.LOADING.CREATING_DEPARTMENT,
            value: true,
        });

        const url = typeToUrl(type);
        const data = {
            title,
            users,
        };

        try {
            const response = await axios.post(url, data);

            const key = typeToStateKey(type);
            const currentData = state[key];
            const newItems = currentData.toSpliced(0, 0, response.data);

            commit(DEPARTMENT_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(newItems) });
        } catch (err) {
            logThrowApiError(err, url, data);
        } finally {
            commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
                key: DEPARTMENT_STORE.LOADING.CREATING_DEPARTMENT,
                value: false,
            });
        }
    },

    [DEPARTMENT_STORE.ACTIONS.UPDATE_DEPARTMENT]: async ({ commit, state }, { type, id, title, users }) => {
        if (state[DEPARTMENT_STORE.LOADING.UPDATING_DEPARTMENT]) {
            return;
        }

        commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
            key: DEPARTMENT_STORE.LOADING.UPDATING_DEPARTMENT,
            value: true,
        });

        const url = typeToUrl(type) + '/' + id;
        const data = {
            title,
            users,
        };

        try {
            const response = await axios.put(url, data);

            const key = typeToStateKey(type);
            const currentData = state[key];

            const index = currentData.findIndex(e => e.id === id);
            const newData = {
                ...currentData[index],
                ...response.data,
            };

            const newItems = currentData.toSpliced(index, 1, newData);

            commit(DEPARTMENT_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(newItems) });
        } catch (err) {
            logThrowApiError(err, url, data);
        } finally {
            commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
                key: DEPARTMENT_STORE.LOADING.UPDATING_DEPARTMENT,
                value: false,
            });
        }
    },

    [DEPARTMENT_STORE.ACTIONS.DELETE_DEPARTMENT]: async ({ commit, state }, { type, id }) => {
        if (state[DEPARTMENT_STORE.LOADING.DELETING_DEPARTMENT]) {
            return;
        }

        commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
            key: DEPARTMENT_STORE.LOADING.DELETING_DEPARTMENT,
            value: true,
        });

        const url = typeToUrl(type) + '/' + id;

        try {
            await axios.delete(url);

            const key = typeToStateKey(type);
            const currentData = state[key];
            const index = currentData.findIndex(e => e.id === id);
            const newItems = currentData.toSpliced(index, 1);

            commit(DEPARTMENT_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(newItems) });
        } catch (err) {
            logThrowApiError(err, url);
        } finally {
            commit(DEPARTMENT_STORE.MUTATIONS.SET_LOADING, {
                key: DEPARTMENT_STORE.LOADING.DELETING_DEPARTMENT,
                value: false,
            });
        }
    },
};

const mutations = {
    [DEPARTMENT_STORE.MUTATIONS.SET_LOADING]: (state, { key, value }) => {
        Vue.set(state[DEPARTMENT_STORE.STATE.LOADING], key, value);
    },
    [DEPARTMENT_STORE.MUTATIONS.SET_DATA]: (state, { key, value }) => {
        Vue.set(state, key, value);
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
