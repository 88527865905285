import FuzzySet from 'fuzzyset.js';
import { mergeArray, mergeObject } from '../../../libs/vuex-merge';
import { get, post, put } from '../../../libs/kk-api';
import { setValue, getValue } from '../../../libs/storage';

export const namespace = 'tripletex';

//
// State
//
function initialState() {
    return {
        employees: [],
        projects: [],
        hourTypes: [],
        overtimeTypes: [],
        salaryTypes: [],
        tripletexProjects: [],
        tripletexEmployees: [],
        tripletexActivities: [],
        additionTypes: [],
        products: [],
        tripletexProducts: [],
        tripletexIntegration: {
            self: null,
            api_key: null,
            is_connected: false,
        },

        loading: {
            employees: false,
            projects: false,
            hourTypes: false,
            overtimeTypes: false,
            salaryTypes: false,
            tripletexProjects: false,
            tripletexEmployees: false,
            tripletexActivities: false,
            additionTypes: false,
            products: false,
            tripletexProducts: false,
            tripletexIntegration: false,
            autoCreateEmployees: false,
            autoCreateProjects: false,
            transferTimetrackings: false,
            tripletexIntegrationConnecting: false,
        },

        filter: {
            tripletexEmployeeCompanies: [],
            showOnlyFilled: false,
            require100Percent: false,
            visible: false,
            hideExpiredCards: false,
        },
    };
}

//
// Mutations
//
const LOADING = 'LOADING';
const FILTER = 'FILTER';
const PROJECTS = 'PROJECTS';
const EMPLOYEES = 'EMPLOYEES';
const HOURTYPES = 'HOURTYPES';
const OVERTIMETYPES = 'OVERTIMETYPES';
const SALARYTYPES = 'SALARYTYPES';
const TRIPLETEX_INTEGRATION = 'TRIPLETEX_INTEGRATION';
const TRIPLETEX_PROJECTS = 'TRIPLETEX_PROJECTS';
const TRIPLETEX_EMPLOYEES = 'TRIPLETEX_EMPLOYEES';
const TRIPLETEX_ACTIVITIES = 'TRIPLETEX_ACTIVITIES';
const ADDITIONTYPES = 'ADDITIONTYPES';
const PRODUCTS = 'PRODUCTS';
const TRIPLETEX_PRODUCTS = 'TRIPLETEX_PRODUCTS';

const mutations = {
    LOADING: (state, diff) => {
        state.loading = mergeObject(state.loading, diff);
    },
    FILTER: (state, diff) => {
        state.filter = mergeObject(state.filter, diff);
    },
    EMPLOYEES: (state, diff) => {
        state.employees = mergeArray(state.employees, diff);
    },
    PROJECTS: (state, diff) => {
        state.projects = mergeArray(state.projects, diff);
    },
    HOURTYPES: (state, diff) => {
        state.hourTypes = mergeArray(state.hourTypes, diff);
    },
    OVERTIMETYPES: (state, diff) => {
        state.overtimeTypes = mergeArray(state.overtimeTypes, diff);
    },
    SALARYTYPES: (state, diff) => {
        state.salaryTypes = mergeArray(state.salaryTypes, diff);
    },
    TRIPLETEX_INTEGRATION: (state, diff) => {
        state.tripletexIntegration = mergeObject(state.tripletexIntegration, diff);
    },
    TRIPLETEX_PROJECTS: (state, diff) => {
        state.tripletexProjects = mergeArray(state.tripletexProjects, diff, 'external_id');
    },
    TRIPLETEX_EMPLOYEES: (state, diff) => {
        state.tripletexEmployees = mergeArray(state.tripletexEmployees, diff, 'external_id');
    },
    TRIPLETEX_ACTIVITIES: (state, diff) => {
        state.tripletexActivities = mergeArray(state.tripletexActivities, diff, 'id');
    },
    ADDITIONTYPES: (state, diff) => {
        state.additionTypes = mergeArray(state.additionTypes, diff, 'id');
    },
    PRODUCTS: (state, diff) => {
        state.products = mergeArray(state.products, diff, 'id');
    },
    TRIPLETEX_PRODUCTS: (state, diff) => {
        state.tripletexProducts = mergeArray(state.tripletexProducts, diff, 'external_id');
    },
};

//
// Getters
//
const getters = {
    isFilterActive: () => {
        return false;
    },

    loading({ loading }) {
        return loading;
    },

    employees: ({ employees }) => {
        return [...employees].sort((a, b) => a.name.localeCompare(b.name));
    },

    projects: ({ projects }) => {
        return [...projects].sort((a, b) => a.name.localeCompare(b.name));
    },

    hourTypes: ({ hourTypes }) => {
        return [...hourTypes].sort((a, b) => a.title.localeCompare(b.title));
    },

    overtimeTypes: ({ overtimeTypes }) => {
        return [...overtimeTypes].sort((a, b) => a.title.localeCompare(b.title));
    },

    salaryTypes: ({ salaryTypes }) => {
        return [...salaryTypes].sort((a, b) => a.name.localeCompare(b.name));
    },

    tripletexActivities: ({ tripletexActivities }) => {
        return [...tripletexActivities].sort((a, b) => a.name.localeCompare(b.name));
    },

    products: ({ products }) => {
        return [...products].sort((a, b) => a.product_name.localeCompare(b.product_name));
    },

    tripletexIntegrationExists: state => state.tripletexIntegration.self !== null,

    linkedTripletexEmployees: ({ tripletexEmployees }) => {
        const filtered = tripletexEmployees.filter(({ employee }) => employee !== null);

        return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },

    unlinkedTripletexEmployees: ({ employees, tripletexEmployees, 'filter': { tripletexEmployeeCompanies, showOnlyFilled, require100Percent, hideExpiredCards } }, { fuzzyEmployeesSet }) => {
        const unlinkedTripletexEmployees = [];

        for (const tripletex_employee of tripletexEmployees) {
            // Filter
            if (tripletex_employee.employee !== null) {
                continue;
            }

            // Filter
            if (tripletexEmployeeCompanies.length !== 0 && (!tripletexEmployeeCompanies.includes(tripletex_employee.organization_name))) {
                continue;
            }

            // Filter
            if (hideExpiredCards && tripletex_employee.expires_at !== null) {
                const expirationDate = moment(tripletex_employee.expires_at).startOf('day');
                const now = moment().startOf('day');
                const diff = expirationDate.diff(now);

                if (diff < 0) {
                    continue;
                }
            }

            const fuzzy = fuzzyEmployeesSet.get(tripletex_employee.name.trim());

            //
            // If the fuzzy is null, set employee to null
            //
            if (fuzzy === null) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            //
            // If fuzzy score is less than 70%, filter away
            //
            if (fuzzyScore < 0.70) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            const employee = employees.find(({ name }) => name.trim() === fuzzyName);
            const alreadyLinkedEmployee = unlinkedTripletexEmployees.some(unlinked => unlinked.employee ? unlinked.employee.id === employee.id : false);

            if (employee === undefined) {
                throw 'Fatal error in unlinkedTripletexEmployees getter: Employee should not be undefined';
            }

            //
            // Only allow kvalitetskontroll employee to be pre-selected once - prevent duplicates
            //
            if (alreadyLinkedEmployee) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            //
            // If the first letter is not identical, ignore
            //
            if (fuzzyScore <= 0.99 && tripletex_employee.first_name[0].toLowerCase() !== employee.first_name[0].toLowerCase()) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            if (fuzzyScore <= 0.99 && tripletex_employee.last_name[0].toLowerCase() !== employee.last_name[0].toLowerCase()) {
                pushUnfilled(tripletex_employee);
                continue;
            }

            unlinkedTripletexEmployees.push({
                tripletex_employee,
                employee,
                fuzzyScore,
                fuzzyName,
            });
        }

        unlinkedTripletexEmployees.sort((a, b) => a.tripletex_employee.name.localeCompare(b.tripletex_employee.name));

        return unlinkedTripletexEmployees;

        function pushUnfilled(tripletex_employee) {
            if (showOnlyFilled) {
                return;
            }
            unlinkedTripletexEmployees.push({
                tripletex_employee,
                employee: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    linkedTripletexProjects: ({ tripletexProjects }) => {
        const filtered = tripletexProjects.filter(({ project }) => project !== null);

        return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },

    unlinkedTripletexProjects: ({ projects, tripletexProjects, 'filter': { showOnlyFilled, require100Percent } }, { fuzzyProjectsSet }) => {
        const unlinkedTripletexProjects = [];

        const pushUnfilled = (tripletex_project) => {
            if (showOnlyFilled || require100Percent) {
                return;
            }
            unlinkedTripletexProjects.push({
                tripletex_project,
                project: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        };

        for (const tripletex_project of tripletexProjects) {
            // Filter
            if (tripletex_project.project !== null) {
                continue;
            }

            if (tripletex_project.end_date !== null && moment(tripletex_project.end_date).isBefore(moment())) {
                continue;
            }

            const fuzzy = fuzzyProjectsSet.get(tripletex_project.name.trim());

            if (fuzzy === null) {
                pushUnfilled(tripletex_project);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                continue;
            }

            //
            // If fuzzy score is less than X%, ignore
            //
            if (Math.floor(fuzzyScore * 100) < 75) {
                pushUnfilled(tripletex_project);
                continue;
            }

            const project = projects.find(({ name }) => name.trim() === fuzzyName);
            const alreadyLinkedProject = unlinkedTripletexProjects.some(unlinked => unlinked.project ? unlinked.project.id === project.id : false);

            if (project === undefined) {
                throw 'Fatal error in unlinkedTripletexProjects getter: project should not be undefined';
            }

            //
            // Only allow kvalitetskontroll project to be pre-selected once - prevent duplicates
            //
            if (alreadyLinkedProject) {
                pushUnfilled(tripletex_project);
                continue;
            }

            //
            // If the first letter is not identical, ignore
            //
            if (project.name.slice(0, 1).toLowerCase() !== tripletex_project.name.slice(0, 1).toLowerCase()) {
                pushUnfilled(tripletex_project);
                continue;
            }

            unlinkedTripletexProjects.push({
                tripletex_project,
                project,
                fuzzyScore,
                fuzzyName,
            });
        }

        unlinkedTripletexProjects.sort((a, b) => a.tripletex_project.name.localeCompare(b.tripletex_project.name));

        return unlinkedTripletexProjects;
    },

    linkedHourTypes: ({ hourTypes }) => {
        var filtered = hourTypes.filter(({ tripletex_activity }) => tripletex_activity !== null);

        filtered = filtered.map((obj) => {
            return {
                ...obj,
                hour_type: obj,
                tripletex_activity: obj.tripletex_activity,
            };
        });

        return filtered.sort((a, b) => a.title.localeCompare(b.title));
    },

    unlinkedHourTypes: ({ hourTypes, tripletexActivities, 'filter': { showOnlyFilled, require100Percent } }, { fuzzyActivitiesSet }) => {
        const unlinkedHourTypes = [];

        for (const type of hourTypes) {
            // Filter
            if (type.tripletex_activity !== null) {
                continue;
            }

            const fuzzy = fuzzyActivitiesSet.get(type.title.trim());

            //
            // If the fuzzy is null, set activity to null
            //
            if (fuzzy === null) {
                pushUnfilled(type);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                pushUnfilled(type);
                continue;
            }

            //
            // If fuzzy score is less than 70%, filter away
            //
            if (fuzzyScore < 0.70) {
                pushUnfilled(type);
                continue;
            }

            const activity = tripletexActivities.find(({ name }) => name.trim() === fuzzyName);

            if (activity === undefined) {
                throw 'Fatal error in unlinkedHourTypes getter: activity should not be undefined';
            }

            //
            // If the first letter is not identical, ignore
            //
            if (fuzzyScore <= 0.99 && type.title[0].toLowerCase() !== activity.name[0].toLowerCase()) {
                pushUnfilled(type);
                continue;
            }

            unlinkedHourTypes.push({
                hour_type: type,
                tripletex_activity: activity,
                fuzzyScore,
                fuzzyName,
            });
        }

        return unlinkedHourTypes;

        function pushUnfilled(hourType) {
            if (showOnlyFilled) {
                return;
            }
            unlinkedHourTypes.push({
                hour_type: hourType,
                tripletex_activity: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    linkedOvertimeTypes: ({ overtimeTypes }) => {
        var filtered = overtimeTypes.filter(({ tripletex_activity }) => tripletex_activity !== null);

        filtered = filtered.map((obj) => {
            return {
                ...obj,
                overtime_type: obj,
                tripletex_activity: obj.tripletex_activity,
            };
        });

        return filtered.sort((a, b) => a.title.localeCompare(b.title));
    },

    unlinkedOvertimeTypes: ({ overtimeTypes, tripletexActivities, 'filter': { showOnlyFilled, require100Percent } }, { fuzzyActivitiesSet }) => {
        const unlinkedOvertimeTypes = [];

        for (const type of overtimeTypes) {
            // Filter
            if (type.tripletex_activity !== null) {
                continue;
            }

            const fuzzy = fuzzyActivitiesSet.get(type.title.trim());

            //
            // If the fuzzy is null, set activity to null
            //
            if (fuzzy === null) {
                pushUnfilled(type);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                pushUnfilled(type);
                continue;
            }

            //
            // If fuzzy score is less than 70%, filter away
            //
            if (fuzzyScore < 0.70) {
                pushUnfilled(type);
                continue;
            }

            const activity = tripletexActivities.find(({ name }) => name.trim() === fuzzyName);

            if (activity === undefined) {
                throw 'Fatal error in unlinkedHourTypes getter: activity should not be undefined';
            }

            //
            // If the first letter is not identical, ignore
            //
            if (fuzzyScore <= 0.99 && type.title[0].toLowerCase() !== activity.name[0].toLowerCase()) {
                pushUnfilled(type);
                continue;
            }

            unlinkedOvertimeTypes.push({
                overtime_type: type,
                tripletex_activity: activity,
                fuzzyScore,
                fuzzyName,
            });
        }

        return unlinkedOvertimeTypes;

        function pushUnfilled(hourType) {
            if (showOnlyFilled) {
                return;
            }
            unlinkedOvertimeTypes.push({
                overtime_type: hourType,
                tripletex_activity: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    linkedAdditiontypes: ({ additionTypes }) => {
        var filtered = additionTypes.filter(({ tripletex_salary_type }) => tripletex_salary_type !== null);

        filtered = filtered.map((obj) => {
            return {
                ...obj,
                additionType: obj,
                salaryType: obj.tripletex_salary_type,
            };
        });

        return filtered.sort((a, b) => a.title.localeCompare(b.title));
    },

    unlinkedAdditiontypes: ({ additionTypes, salaryTypes, 'filter': { showOnlyFilled, require100Percent } }, { fuzzySalaryTypesSet }) => {
        const unlinkedAdditiontypes = [];

        for (const addition of additionTypes) {
            // Filter
            if (addition.tripletex_salary_type !== null) {
                continue;
            }

            const fuzzy = fuzzySalaryTypesSet.get(addition.title.trim());

            //
            // If the fuzzy is null, set addition to null
            //
            if (fuzzy === null) {
                pushUnfilled(addition);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                pushUnfilled(addition);
                continue;
            }

            //
            // If fuzzy score is less than 70%, filter away
            //
            if (fuzzyScore < 0.70) {
                pushUnfilled(addition);
                continue;
            }

            const salaryType = salaryTypes.find(({ name }) => name.trim() === fuzzyName);
            const alreadyLinkedSalaryType = unlinkedAdditiontypes.some(unlinked => unlinked.tripletex_salary_type ? unlinked.tripletex_salary_type.id === salaryType.id : false);

            if (salaryType === undefined) {
                throw 'Fatal error in unlinkedAdditiontypes getter: salaryType should not be undefined';
            }

            //
            // Only allow kvalitetskontroll employee to be pre-selected once - prevent duplicates
            //
            if (alreadyLinkedSalaryType) {
                pushUnfilled(addition);
                continue;
            }

            //
            // If the first letter is not identical, ignore
            //
            if (fuzzyScore <= 0.99 && addition.title[0].toLowerCase() !== salaryType.name[0].toLowerCase()) {
                pushUnfilled(addition);
                continue;
            }

            unlinkedAdditiontypes.push({
                additionType: addition,
                salaryType,
                fuzzyScore,
                fuzzyName,
            });
        }

        // unlinkedAdditiontypes.sort((a, b) => a.tripletex_employee.name.localeCompare(b.tripletex_employee.name));

        return unlinkedAdditiontypes;

        function pushUnfilled(additionType) {
            if (showOnlyFilled) {
                return;
            }
            unlinkedAdditiontypes.push({
                additionType: additionType,
                salaryType: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        }
    },

    linkedTripletexProducts: ({ tripletexProducts }) => {
        const filtered = tripletexProducts.filter(({ product }) => product !== null);

        return filtered.sort((a, b) => a.name.localeCompare(b.name));
    },

    unlinkedTripletexProducts: ({ products, tripletexProducts, 'filter': { showOnlyFilled, require100Percent } }, { fuzzyProductsSet }) => {
        const unlinkedTripletexProducts = [];

        const pushUnfilled = (tripletex_product) => {
            if (showOnlyFilled || require100Percent) {
                return;
            }
            unlinkedTripletexProducts.push({
                tripletex_product,
                product: null,
                fuzzyScore: null,
                fuzzyName: null,
            });
        };

        for (const tripletex_product of tripletexProducts) {
            // Filter
            if (tripletex_product.product !== null) {
                continue;
            }

            const fuzzy = fuzzyProductsSet.get(tripletex_product.name.trim());

            if (fuzzy === null) {
                pushUnfilled(tripletex_product);
                continue;
            }

            const fuzzyScore = fuzzy[0][0];
            const fuzzyName = fuzzy[0][1].trim();

            if (require100Percent && fuzzyScore !== 1) {
                continue;
            }

            //
            // If fuzzy score is less than X%, ignore
            //
            if (Math.floor(fuzzyScore * 100) < 75) {
                pushUnfilled(tripletex_product);
                continue;
            }
            const product = products.find(({ product_name }) => product_name.trim() === fuzzyName);
            const alreadyLinkedProduct = unlinkedTripletexProducts.some(unlinked => unlinked.product ? unlinked.product.id === product.id : false);

            if (product === undefined) {
                throw 'Fatal error in unlinkedTripletexProducts getter: product should not be undefined';
            }

            //
            // Only allow kvalitetskontroll product to be pre-selected once - prevent duplicates
            //
            if (alreadyLinkedProduct) {
                pushUnfilled(tripletex_product);
                continue;
            }

            //
            // If the first letter is not identical, ignore
            //
            if (product.product_name.slice(0, 1).toLowerCase() !== tripletex_product.name.slice(0, 1).toLowerCase()) {
                pushUnfilled(tripletex_product);
                continue;
            }

            unlinkedTripletexProducts.push({
                tripletex_product,
                product,
                fuzzyScore,
                fuzzyName,
            });
        }

        unlinkedTripletexProducts.sort((a, b) => a.tripletex_product.name.localeCompare(b.tripletex_product.name));

        return unlinkedTripletexProducts;
    },

    fuzzyEmployeesSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.employees) {
            fuzzySet.add(name.trim());
        }

        return fuzzySet;
    },

    fuzzyProjectsSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.projects) {
            fuzzySet.add(name.trim());
        }

        return fuzzySet;
    },

    fuzzyActivitiesSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.tripletexActivities) {
            fuzzySet.add(name.trim());
        }

        return fuzzySet;
    },

    fuzzySalaryTypesSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { name } of state.salaryTypes) {
            fuzzySet.add(name.trim());
        }

        return fuzzySet;
    },

    fuzzyProductsSet: (state) => {
        const fuzzySet = FuzzySet();

        for (const { product_name } of state.products) {
            fuzzySet.add(product_name.trim());
        }

        return fuzzySet;
    },
};

//
// Actions
//
const actions = {

    loadFilter: async ({ commit }, user) => {
        const filter = await getValue(FILTER, user.id, { namespace, defaultValue: {} });
        commit(FILTER, filter);
    },

    loadTripletexIntegration: async ({ commit }) => {
        commit(LOADING, { tripletexIntegration: true });

        try {
            const tripletexIntegrations = await get('/tripletex_integrations', { headers: { Accept: 'application/json' } });
            const tripletexIntegration = tripletexIntegrations[0];
            commit(TRIPLETEX_INTEGRATION, tripletexIntegration);
        } finally {
            commit(LOADING, { tripletexIntegration: false });
        }
    },

    loadEmployees: async ({ commit }) => {
        commit(LOADING, { employees: true });

        try {
            const employees = await get('/employees');
            commit(EMPLOYEES, employees);
        } finally {
            commit(LOADING, { employees: false });
        }
    },

    loadHourTypes: async ({ commit }) => {
        commit(LOADING, { hourtypes: true });

        try {
            const hourtypes = await get('/tripletex_hourtypes');
            commit(HOURTYPES, hourtypes);
        } finally {
            commit(LOADING, { hourtypes: false });
        }
    },

    loadOvertimeTypes: async ({ commit }) => {
        commit(LOADING, { overtimeTypes: true });

        try {
            const overtimeTypes = await get('/tripletex_overtimetypes');
            commit(OVERTIMETYPES, overtimeTypes);
        } finally {
            commit(LOADING, { overtimeTypes: false });
        }
    },

    loadSalaryTypes: async ({ commit }) => {
        commit(LOADING, { salaryTypes: true });

        try {
            const salaryTypes = await get('/tripletex_salarytypes');

            commit(SALARYTYPES, salaryTypes);
        } finally {
            commit(LOADING, { salaryTypes: false });
        }
    },

    loadAdditionTypes: async ({ commit }) => {
        commit(LOADING, { additionTypes: true });

        try {
            const additionTypes = await get('/tripletex_additiontypes');

            commit(ADDITIONTYPES, additionTypes);
        } finally {
            commit(LOADING, { additionTypes: false });
        }
    },

    loadProjects: async ({ commit }) => {
        commit(LOADING, { projects: true });

        try {
            let projects = await get('/projects');

            // Add key numberName to all projects: "3 | my project". Used for label on select
            if (Array.isArray(projects)) {
                projects = projects.map((project) => {
                    if (project.project_no !== null && project.project_no !== '') {
                        project.numberName = `${project.project_no} | ${project.name}`;
                    } else {
                        project.numberName = project.name;
                    }

                    return project;
                });
            }

            commit(PROJECTS, projects);
        } finally {
            commit(LOADING, { projects: false });
        }
    },

    loadProducts: async ({ commit }) => {
        commit(LOADING, { products: true });

        try {
            let products = await get('/products?display=1000000');
            products = products.data;

            // Add key numberName to all products: "3 | my product". Used for label on select
            if (Array.isArray(products)) {
                products = products.map((product) => {
                    if (product.product_number !== null && product.product_number !== '') {
                        product.numberName = `${product.product_number} | ${product.product_name}`;
                    } else {
                        product.numberName = product.product_name;
                    }

                    return product;
                });
            }

            commit(PRODUCTS, products);
        } finally {
            commit(LOADING, { products: false });
        }
    },

    loadTripletexProducts: async ({ commit }) => {
        commit(LOADING, { tripletexProducts: true });

        try {
            const tripletexProducts = await get('/tripletex_products');
            commit(TRIPLETEX_PRODUCTS, tripletexProducts);
        } finally {
            commit(LOADING, { tripletexProducts: false });
        }
    },

    loadTripletexProjects: async ({ commit }) => {
        commit(LOADING, { tripletexProjects: true });

        try {
            const tripletexProjects = await get('/tripletex_projects');
            commit(TRIPLETEX_PROJECTS, tripletexProjects);
        } finally {
            commit(LOADING, { tripletexProjects: false });
        }
    },

    loadTripletexEmployees: async ({ commit }) => {
        commit(LOADING, { tripletexEmployees: true });

        try {
            let tripletexEmployees = await get('/tripletex_employees');

            // Generate new key "name" from last name & first name
            tripletexEmployees = tripletexEmployees.map(employee => ({ ...employee, name: `${employee.first_name} ${employee.last_name}` }));

            commit(TRIPLETEX_EMPLOYEES, tripletexEmployees);
        } finally {
            commit(LOADING, { tripletexEmployees: false });
        }
    },

    loadTripletexActivities: async ({ commit }) => {
        commit(LOADING, { tripletexActivities: true });

        try {
            const tripletexActivities = await get('/tripletex_activities');
            commit(TRIPLETEX_ACTIVITIES, tripletexActivities);
        } finally {
            commit(LOADING, { tripletexActivities: false });
        }
    },

    updateFilter: async ({ state, commit }, { filter, user }) => {
        commit(FILTER, filter);
        setValue(FILTER, state.filter, user.id, { namespace });
    },

    updateTripletexIntegration: async ({ commit, state }, { payload, loadingFlag = null }) => {
        commit(LOADING, { tripletexIntegration: true, [loadingFlag]: true });

        try {
            const tripletexIntegration = await put(state.tripletexIntegration.self, payload);
            commit(TRIPLETEX_INTEGRATION, tripletexIntegration);
        } finally {
            commit(LOADING, { tripletexIntegration: false, [loadingFlag]: false });
        }
    },

    connectTripletexIntegration: async ({ state, commit }) => {
        commit(LOADING, { tripletexIntegrationConnecting: true });

        try {
            const tripletexIntegration = await post(state.tripletexIntegration.connect);
            commit(TRIPLETEX_INTEGRATION, tripletexIntegration);
        } finally {
            commit(LOADING, { tripletexIntegrationConnecting: false });
        }
    },

    disconnectTripletexIntegration: async ({ commit, state }) => {
        commit(LOADING, { tripletexIntegrationConnecting: true });

        try {
            const tripletexIntegration = await post(state.tripletexIntegration.disconnect);
            commit(TRIPLETEX_INTEGRATION, tripletexIntegration);
        } finally {
            commit(LOADING, { tripletexIntegrationConnecting: false });
        }
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].employee
     */
    updateTripletexEmployees: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { tripletexEmployees: true });

        try {
            const updatedEmployees = await put('/tripletex_employees', payload);
            commit(TRIPLETEX_EMPLOYEES, updatedEmployees);
        } finally {
            commit(LOADING, { tripletexEmployees: false });
        }
        dispatch('loadEmployees');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateTripletexProjects: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { tripletexProjects: true });

        try {
            const updatedProjects = await put('/tripletex_projects', payload);
            commit(TRIPLETEX_PROJECTS, updatedProjects);
        } finally {
            commit(LOADING, { tripletexProjects: false });
        }
        dispatch('loadProjects');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateTripletexProducts: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { tripletexProducts: true });

        try {
            const updatedProducts = await put('/tripletex_products', payload);
            commit(TRIPLETEX_PRODUCTS, updatedProducts);
        } finally {
            commit(LOADING, { tripletexProducts: false });
        }
        dispatch('loadProducts');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateHourTypes: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { hourTypes: true });

        try {
            const updatedHourTypes = await put('/tripletex_hourtypes', payload);
            commit(HOURTYPES, updatedHourTypes);
        } finally {
            commit(LOADING, { hourTypes: false });
        }
        dispatch('loadHourTypes');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateOvertimeTypes: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { overtimeTypes: true });

        try {
            const updatedOvertimeTypes = await put('/tripletex_overtimetypes', payload);
            commit(OVERTIMETYPES, updatedOvertimeTypes);
        } finally {
            commit(LOADING, { overtimeTypes: false });
        }
        dispatch('loadOvertimeTypes');
    },

    /**
     * @param {Object[]} payload
     * @param {Number} payload[].external_id
     * @param {Object} payload[].project
     */
    updateAdditionTypes: async ({ commit, dispatch }, payload) => {
        commit(LOADING, { additionTypes: true });

        try {
            const updatedAdditionTypes = await put('/tripletex_additiontypes', payload);
            commit(ADDITIONTYPES, updatedAdditionTypes);
        } finally {
            commit(LOADING, { additionTypes: false });
        }
        dispatch('loadAdditionTypes');
    },
};

//
// Export
//
export const store = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
