import { mergeObject, mergeArray } from '../../../libs/vuex-merge';
import { ks } from 'vue-components';
import { createNamespacedHelpers } from 'vuex';

export const namespace = 'hourStamp';
const { mapActions, mapState, mapGetters } = createNamespacedHelpers(namespace);

/**
 * @readonly
 * @enum {string}
 */
export const Scope = {
    Default: 'default',
    WithIgnored: 'withIgnored',
};

const state = () => ({
    stamps: [],
    filter: {
        scope: Scope.Default,
        from: moment().startOf('week').subtract(1, 'week'),
    },
    loading: {
        stamps: false,
    },
});

const getters = {
    stamps: (state) => {
        let stamps = [];

        switch (state.filter.scope) {
            case Scope.Default: {
                stamps = state.stamps.filter(stamp => stamp.deleted_at === null);
            } break;
            case Scope.OnlyIgnored: {
                stamps = state.stamps.filter(stamp => stamp.deleted_at !== null);
            } break;
            case Scope.WithIgnored: {
                stamps = state.stamps;
            } break;
        }

        stamps = stamps.filter(stamp => state.filter.from.isSameOrBefore(stamp.time));

        return stamps;
    },
};

const LOADING = 'LOADING';
const STAMPS = 'STAMPS';
const FILTER = 'FILTER';

const mutations = {
    STAMPS: (state, diff) => {
        state.stamps = mergeArray(state.stamps, diff);
    },
    LOADING: (state, diff) => {
        state.loading = mergeObject(state.loading, diff);
    },
    FILTER: (state, diff) => {
        state.filter = mergeObject(state.filter, diff);
    },
};

const LoadStamps = 'loadStamps';

const actions = {
    loadStamps: async ({ state, commit }) => {
        commit(LOADING, { stamps: true });
        let stamps = null;

        try {
            stamps = (await ks.get('/api/hour-stamps', {
                scope: Scope.WithIgnored, // Always cache ignored, hide them in fe
                from: state.filter.from.format(),
            })).data;
        } finally {
            commit(LOADING, { stamps: false });
        }

        commit(STAMPS, stamps);
    },

    /**
     * @param {number[]} stampIds array of stamp ids
     */
    deleteStamps: async ({ commit }, stamp_ids) => {
        commit(LOADING, { stamps: true });
        let deletedStamps = null;

        try {
            deletedStamps = (await ks.delete('/api/hour-stamps', {
                stamp_ids,
            })).data.data;
        } finally {
            commit(LOADING, { stamps: false });
        }

        commit(STAMPS, deletedStamps);
    },

    loadMoreStamps: async ({ commit, state, dispatch }) => {
        const from = state.filter.from.subtract(1, 'week');
        commit(FILTER, { from });
        dispatch(LoadStamps);
    },

    toggleIgnoredStamps: ({ state, commit }) => {
        const scope = state.filter.scope === Scope.WithIgnored ? Scope.Default : Scope.WithIgnored;
        commit(FILTER, { scope });
    },
};

export const {
    filter,
    loading,
} = mapState([
    'filter',
    'loading',
]);

export const {
    stamps,
} = mapGetters([
    'stamps',
]);

export const {
    loadStamps,
    deleteStamps,
    toggleIgnoredStamps,
    loadMoreStamps,
} = mapActions([
    'loadStamps',
    'deleteStamps',
    'toggleIgnoredStamps',
    'loadMoreStamps',
]);

export const store = {
    state,
    actions,
    mutations,
    getters,
    namespaced: true,
};
