import axios from 'axios';

const isMobile = location.pathname.includes('/mobile');

export const user = {
    state: {
        company: {},
        links: [],
        loginTimedout: false,
        modules: {},
        projects: {
            all: [],
            current: {
                id: null,
            },
        },
        roles: {},
        permissions: {
            order: {
                canRead: false,
                canWrite: false,
            },
            customForms: {
                canComplete: false,
            },
        },
        timeregTimer: {
            start: null,
            stop: null,
        },
        user: {},
        company_user: {},
        userDataFetched: false,
        currentProjectSettingsFetched: false,
        currentProjectSettings: {
            filters: {
                checklists: {
                    categories: [],
                },
            },
        },
    },
    getters: {
        getUserState: state => state,
        getUser: state => state.user,
        getUserDataFetched: state => state.userDataFetched,
        getUserId: state => state.user.id,
        getCompanyUser: state => state.company_user,
        getUserRoles: state => state.roles,
        getUserLocale: state => state.user.language,
        getUserFullName: state => `${state.user.firstname} ${state.user.lastname}`,
        getUserPermissions: state => state.permissions,
        currentProject: state => state.projects.current,
        currentProjectId: state => state.projects.current.id,
        getCurrentProjectChecklistsFilters: state => state.currentProjectSettings.filters.checklists,
        getCurrentProjectSettingsFetched: state => state.currentProjectSettingsFetched,
        getActiveProjects: state => state.projects.all.filter(p => !p.isArchived),
        getAllProjects: state => state.projects.all,
    },
    mutations: {
        LOGIN_TIMEDOUT_SET(state, bool) {
            state.loginTimedout = bool;
        },

        USER_SET(state, data) {
            state.company = data.company;
            state.links = data.links;
            state.modules = data.modules;
            state.roles = data.roles;
            state.permissions = data.permissions;
            state.projects = data.projects;
            state.timeregTimer = data.timeregTimer;
            state.user = data.user;
            state.company_user = data.company_user;
        },

        USER_DATA_FETCHED_SET(state, bool) {
            state.userDataFetched = bool;
        },

        CURRENT_PROJECT_SET(state, projectId) {
            // Gets new projectId from select then loops trough state.projects to find
            // the new selected project and updates currentProject object with new selected obj.
            let current = state.projects.all.find(p => p.id === projectId);

            if (typeof current !== 'undefined') {
                state.projects.current = current;
            }
        },

        CURRENT_PROJECT_SET_NOT_SELECTED(state) {
            state.projects.current = {
                id: -1,
                numberName: Lang.get('errors.Ingen prosjekt valgt'),
            };
        },

        CLEAR_CURRENT_PROJECT(state) {
            state.projects.current = {
                id: null,
            };
        },

        ADD_PROJECT(state, projectData) {
            state.projects.all.push(projectData);
        },

        UPDATE_PROJECT(state, projectData) {
            const index = state.projects.all.findIndex(p => p.id = projectData.id);
            state.projects.all.splice(index, 1, projectData);
        },

        UPDATE_PROJECT_SETTINGS(state, projectSettings) {
            state.currentProjectSettings = projectSettings;
        },

        UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS(state, filter) {
            state.currentProjectSettings.filters.checklists = { ...state.currentProjectSettings.filters.checklists, ...filter };
        },

        UPDATE_CURRENT_PROJECT_SETTINGS_FETCHED(state, payload) {
            state.currentProjectSettingsFetched = payload;
        },

        UPDATE_USER_CHECKLISTS_FILTER_SETTINGS(state, filter) {
            state.user.settings.filters.checklists = { ...state.user.settings.filters.checklists, ...filter };
        },

        UPDATE_CURRENT_PROJECT_SETTINGS_ORDER_BY(state, value) {
            state.currentProjectSettings.filters.checklists.orderBy = value;
        },

        UPDATE_USER_HAS_TWO_FACTOR_AUTH(state, value) {
            state.user.hasTwoFactor = value;
        },
    },
    actions: {
        setLoginTimedout: async ({ commit }, bool) => {
            commit('LOGIN_TIMEDOUT_SET', bool);
        },

        getUser({ commit, dispatch, state }) {
            return axios.get('/api/user').then((response) => {
                let data = response.data.data;
                commit('USER_SET', data);

                if (window.Sentry) {
                    // Add user to sentry for reference to who has errors.
                    Sentry.getCurrentScope().setUser({
                        id: data.user.id,
                        email: data.user.email,
                        name: `${data.user.firstname} ${data.user.lastname}`,
                        username: data.user.username,
                    });
                    Sentry.getCurrentScope().setTags({
                        'company.id': data.company.id,
                        'company.name': data.company.name,
                    });

                    const userData = JSON.parse(JSON.stringify(data));
                    delete userData.projects.all;
                    Sentry.getCurrentScope().setExtra('User object', userData);
                }

                // Update Intercom meta data
                if ('Intercom' in window) {
                    let plans = { 1: 'KS Enkel', 2: 'KS Basis', 3: 'KS Pluss', 4: 'KS Premium' };

                    const productionVariables = {
                        hide_default_launcher: isMobile,
                        company: {
                            city: data.company.city,
                            company_id: data.company.id,
                            created_at: moment(data.company.created_at).unix(),
                            name: data.company.name,
                            plan: data.company.subscription_code,
                            plan_name: plans[data.company.subscription_code],
                            // If HR module is on and license is 1, they actually have 5
                            size: (data.company.hr && data.company.size === 1) ? 5 : data.company.size,
                            website: data.company.web,
                            // Custom
                            company_email: data.company.email,
                            demo: data.company.demo ? 'yes' : 'no',
                            organisation_number: data.company.orgnr,
                            zip_code: data.company.zip,
                            support_member: data.company.support_member,
                            last_revision_date: moment(data.company.last_revision_date).unix(),
                            // Exports, Integrations and Modules
                            export_custom: data.modules.exports.custom ? 'yes' : 'no',
                            export_24sevenoffice: data.modules.exports._24sevenoffice ? 'yes' : 'no',
                            export_duett: data.modules.exports.duett ? 'yes' : 'no',
                            export_huldt_og_lillevik: data.modules.exports.huldt_og_lillevik ? 'yes' : 'no',
                            export_nettlonn: data.modules.exports.nettlonn ? 'yes' : 'no',
                            export_power_office_go: data.modules.exports.poweroffice_go ? 'yes' : 'no',
                            export_tripletex: data.modules.exports.tripletex ? 'yes' : 'no',
                            export_uni_economy: data.modules.exports.uni_economy ? 'yes' : 'no',
                            export_visma: data.modules.exports.visma ? 'yes' : 'no',
                            export_xledger: data.modules.exports.xledger ? 'yes' : 'no',
                            integration_infotech: data.modules.integrations.infotech ? 'yes' : 'no',
                            integration_tripletex: data.modules.integrations.tripletex ? 'yes' : 'no',
                            module_abax: data.modules.abax ? 'yes' : 'no',
                            module_sms: data.modules.sms ? 'yes' : 'no',
                            module_orders: data.modules.orders ? 'yes' : 'no',
                            module_inspections: data.modules.inspections ? 'yes' : 'no',
                            module_norkart: data.modules.norkart ? 'yes' : 'no',
                            module_ns_contracts: data.modules.ns_contracts ? 'yes' : 'no',
                            module_top_logo: data.modules.topplogo ? 'yes' : 'no',
                            module_hour_budget: data.modules.hour_budget ? 'yes' : 'no',
                            module_procedures: data.modules.procedures ? 'yes' : 'no',
                            module_infotech_crew_list: data.modules.infotech_crew_list ? 'yes' : 'no',
                            module_time_tracking: data.modules.time_tracking ? 'yes' : 'no',
                            time_tracking_disable_hourbank: data.modules.disable_hourbank ? 'yes' : 'no',
                        },
                        email: data.user.email,
                        first_name: data.user.firstname,
                        last_name: data.user.lastname,
                        name: `${data.user.firstname} ${data.user.lastname}`,
                        phone: data.user.mobile ? data.user.mobile : data.user.phone,
                        user_id: data.user.id,
                        user_hash: data.user.user_hash,
                        // Custom
                        role_admin: data.roles.admin ? 'yes' : 'no',
                        role_hr_admin: data.roles.hr_admin ? 'yes' : 'no',
                        role_project_manager: data.roles.projectManager ? 'yes' : 'no',
                        test_user: location.hostname === 'ks.kvalitetskontroll.no' ? 'no' : 'yes',
                        role_time_tracking_admin: data.roles.timeAdmin ? 'yes' : 'no',
                    };

                    const stagingVariables = {
                        hide_default_launcher: true,
                    };

                    if (import.meta.env.VITE_APP_ENV === 'production') {
                        window.Intercom('update', productionVariables);
                    } else {
                        window.Intercom('update', stagingVariables);
                    }
                }

                // Update mixpanel user
                if (window.mixpanel) {
                    window.mixpanel.identify(data.user.id);
                    let plans = { 1: 'KS Enkel', 2: 'KS Basis', 3: 'KS Pluss', 4: 'KS Premium' };

                    const super_data = {
                        '$name': `${data.user.firstname} ${data.user.lastname}`,
                        '$first_name': data.user.firstname,
                        '$last_name': data.user.lastname,
                        '$email': data.user.email,
                        'Company Id': data.company.id,
                        'Company Name': data.company.name,
                        'Company Subscription': plans[data.company.subscription_code],
                        'Is Demo': data.company.demo,
                        'Licenses': (data.company.hr && data.company.size === 1) ? 5 : data.company.size,
                        'Is Admin': data.roles.admin,
                        'Is HR Admin': data.roles.hr_admin,
                        'Is Project Manager': data.roles.projectManager,
                        'Is Time Tracking Admin': data.roles.timeAdmin,
                    };
                    window.mixpanel.people.set(super_data);
                    window.mixpanel.register(super_data);
                }

                dispatch('getProjectSettings', state.projects.current.id);

                commit('USER_DATA_FETCHED_SET', true);
            }, (error) => {
                let redirectToHome = () => {
                    // User api returns status 401 on unauthorized, redirect
                    // to mobile login page
                    let response = error.response
                        ? error.response
                        : error.request ? error.request : {};

                    if (response.status && response.status === 401) {
                        location.href = isMobile ? '/mobile' : '/';
                    }
                };

                redirectToHome();
            });
        },

        setCurrentProject({ commit, dispatch }, projectId) {
            dispatch('getProjectSettings', projectId);

            return axios.post('/api/projects/current', { pid: projectId }).then(() => {
                if (projectId === -1) {
                    commit('CURRENT_PROJECT_SET_NOT_SELECTED');
                } else {
                    commit('CURRENT_PROJECT_SET', projectId);
                }
            }, () => {
                alert('Cant change project');
            });
        },

        clearCurrentProject({ commit }) {
            return axios.post('/api/projects/current', { pid: 0 }).then(() => {
                commit('CLEAR_CURRENT_PROJECT');
            }, () => {
                alert('Cant reset project');
            });
        },

        addProject({ commit }, projectData) {
            return axios.post('/api/projects', projectData).then((res) => {
                commit('ADD_PROJECT', res.data);

                return res.data;
            });
        },

        updateProject({ commit }, projectData) {
            return axios.put('/api/projects/' + projectData.id, projectData).then((res) => {
                commit('UPDATE_PROJECT', res.data);

                return res.data;
            });
        },

        getProjectSettings({ commit }, projectId) {
            if (projectId < 1) {
                return;
            }

            return axios.get(`/api/projects/${projectId}/settings`).then((response) => {
                commit('UPDATE_PROJECT_SETTINGS', response.data);
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FETCHED', true);
            });
        },

        updateCurrentProjectFilterChecklistsShowArchivedSetting({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-show-archived-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { showArchived: value });
            });
        },

        updateCurrentProjectFilterChecklistsShowCompletedSetting({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-show-completed-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { showCompleted: value });
            });
        },

        updateCurrentProjectFilterChecklistsCategoryExactMatchSetting({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-category-exact-match-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { categoryExactMatch: value });
            });
        },

        updateCurrentProjectFilterChecklistSortedBy({ commit, state }, value) {
            let data = {
                by: value,
                direction: 'DESC',
            };

            if (value === 'title') {
                data.direction = 'ASC';
            }

            const userId = state.user.id;

            if (userId < 1) {
                return;
            }

            return axios.put(`/api/users/${userId}/filter-checklists-order-by-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_ORDER_BY', value);
            });
        },

        updateCurrentProjectFilterChecklistWhereCategoryChoiceSetting({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-where-category-choice-setting`, data).then(() => {
                if (value === null) {
                    value = [];
                }

                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { categories: value });
            });
        },

        updateCurrentProjectFilterChecklistsSelectedCategorySetting({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-selected-category-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { selectedCategory: value });
            });
        },

        updateCurrentProjectFilterChecklistsSelectedAssignedTo({ commit, state }, value) {
            const data = {
                value,
            };

            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.put(`/api/projects/${projectId}/filter-checklists-where-assigned-user-setting`, data).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { assignedUsers: value });
            });
        },

        resetCurrentProjectFilterChecklistsSettings({ commit, state }) {
            const projectId = state.projects.current.id;

            if (projectId < 1) {
                return;
            }

            return axios.patch(`/api/projects/${projectId}/reset-filter-checklists-settings`).then(() => {
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { selectedCategory: null });
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { categories: [] });
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { categoryExactMatch: 1 });
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { showArchived: 0 });
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { showCompleted: null });
                commit('UPDATE_CURRENT_PROJECT_SETTINGS_FILTER_CHECKLISTS', { assignedUsers: [] });
            });
        },

        updateUserChecklistsRowsPerPageFilterSetting({ commit, state }, value) {
            const userId = state.user.userId;

            if (userId === undefined) {
                return;
            }

            const data = {
                value,
            };

            return axios.put(`/api/users/${userId}/filter-checklists-rows-per-page-setting`, data).then(() => {
                commit('UPDATE_USER_CHECKLISTS_FILTER_SETTINGS', { rowsPerPage: value });
            });
        },

        updateUserChecklistsOrderSetting({ commit, state }, payload) {
            const userId = state.user.userId;

            if (userId === undefined) {
                return;
            }

            return axios.put(`/api/users/${userId}/filter-checklists-order-by-setting`, payload).then(() => {
                commit('UPDATE_USER_CHECKLISTS_FILTER_SETTINGS', { orderBy: payload.by });
                commit('UPDATE_USER_CHECKLISTS_FILTER_SETTINGS', { orderDirection: payload.direction });
            });
        },

        updateUserHasTwoFactorAuth({ commit, state }, value) {
            const userId = state.user.userId;

            if (userId === undefined) {
                return;
            }

            commit('UPDATE_USER_HAS_TWO_FACTOR_AUTH', value);
        },
    },
};
