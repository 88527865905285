import Vue from 'vue';
import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';

export const namespace = 'boligmappa';
const BOLIGMAPPA_INTEGRATION_URL = '/api/integrations/boligmappa';
const CHECKLIST_RULES_URL = '/api/integrations/boligmappa/checklists/rules';
const CHECKLIST_RULES_PREVIEW_URL = '/api/integrations/boligmappa/checklists/rules/preview';

const CUSTOM_FORMS_RULES_URL = '/api/integrations/boligmappa/custom_forms/rules';
const CUSTOM_FORMS_RULES_PREVIEW_URL = '/api/integrations/boligmappa/custom_forms/rules/preview';

export const BOLIGMAPPA_STORE = {
    STATE: {
        LOADING: 'LOADING',
        SELECTED_PROJECT: 'SELECTED_PROJECT',
        BOLIGMAPPA_INTEGRATION: 'BOLIGMAPPA_INTEGRATION',
        BOLIGMAPPA_AUTHORIZATION_URL: 'BOLIGMAPPA_AUTHORIZATION_URL',
        BOLIGMAPPA_CHECKLIST_RULES: 'BOLIGMAPPA_CHECKLIST_RULES',
        BOLIGMAPPA_CHECKLIST_RULES_PREVIEW: 'BOLIGMAPPA_CHECKLIST_RULES_PREVIEW',
        BOLIGMAPPA_CUSTOM_FORMS_RULES: 'BOLIGMAPPA_CUSTOM_FORMS_RULES',
        BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW: 'BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW',
    },
    ACTIONS: {
        DISCONNECT_INTEGRATION: 'DISCONNECT_INTEGRATION',
        FETCH_BOLIGMAPPA_INTEGRATION: 'FETCH_BOLIGMAPPA_INTEGRATION',
        FETCH_BOLIGMAPPA_AUTHORIZATION_URL: 'FETCH_BOLIGMAPPA_AUTHORIZATION_URL',

        FETCH_BOLIGMAPPA_CHECKLIST_RULES: 'FETCH_BOLIGMAPPA_CHECKLIST_RULES',
        CREATE_BOLIGMAPPA_CHECKLIST_RULE: 'CREATE_BOLIGMAPPA_CHECKLIST_RULE',
        UPDATE_BOLIGMAPPA_CHECKLIST_RULE: 'UPDATE_BOLIGMAPPA_CHECKLIST_RULE',
        DELETE_BOLIGMAPPA_CHECKLIST_RULE: 'DELETE_BOLIGMAPPA_CHECKLIST_RULE',
        SEND_ALL_CHECKLISTS_TOGGLE: 'SEND_ALL_CHECKLISTS_TOGGLE',
        FETCH_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW: 'FETCH_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW',

        FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES: 'FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES',
        CREATE_BOLIGMAPPA_CUSTOM_FORMS_RULE: 'CREATE_BOLIGMAPPA_CUSTOM_FORMS_RULE',
        UPDATE_BOLIGMAPPA_CUSTOM_FORMS_RULE: 'UPDATE_BOLIGMAPPA_CUSTOM_FORMS_RULE',
        DELETE_BOLIGMAPPA_CUSTOM_FORMS_RULE: 'DELETE_BOLIGMAPPA_CUSTOM_FORMS_RULE',
        SEND_ALL_CUSTOM_FORMS_TOGGLE: 'SEND_ALL_CUSTOM_FORMS_TOGGLE',
        FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW: 'FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW',

    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
        SET_BOLIGMAPPA_INTEGRATION: 'SET_BOLIGMAPPA_INTEGRATION',
        SET_BOLIGMAPPA_AUTHORIZATION_URL: 'SET_BOLIGMAPPA_AUTHORIZATION_URL',
        SET_BOLIGMAPPA_CHECKLIST_RULES: 'SET_BOLIGMAPPA_CHECKLIST_RULES',
        SET_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW: 'SET_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW',
        SET_BOLIGMAPPA_CUSTOM_FORMS_RULES: 'SET_BOLIGMAPPA_CUSTOM_FORMS_RULES',
        SET_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW: 'SET_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW',
    },
};

function initialState() {
    return {
        [BOLIGMAPPA_STORE.STATE.SELECTED_PROJECT]: -1,
        [BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_INTEGRATION]: {},
        [BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CHECKLIST_RULES]: [],
        [BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CHECKLIST_RULES_PREVIEW]: [],
        [BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CUSTOM_FORMS_RULES]: [],
        [BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW]: [],
        [BOLIGMAPPA_STORE.STATE.LOADING]: {
            boligmappaIntegration: false,
        },
    };
}

const actions = {
    [BOLIGMAPPA_STORE.ACTIONS.DISCONNECT_INTEGRATION]: async ({ commit }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: true });

        try {
            const result = await axios.get(BOLIGMAPPA_INTEGRATION_URL + '/disconnectIntegration');
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_INTEGRATION, result.data);
        } finally {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: false });
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_AUTHORIZATION_URL]: async ({ commit }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: true });

        try {
            const result = await axios.get(BOLIGMAPPA_INTEGRATION_URL + '/authorization');
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_AUTHORIZATION_URL, result.data);
        } finally {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: false });
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_INTEGRATION]: async ({ commit }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: true });

        try {
            const result = await axios.get(BOLIGMAPPA_INTEGRATION_URL + '/connection');
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_INTEGRATION, result.data);
        } finally {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'boligmappaIntegration', value: false });
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW]: async ({ commit }, { projectId }) => {
        if (projectId > 0) {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRulesPreview', value: true });
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_SELECTED_PROJECT, projectId);

            try {
                const result = await axios.get(`${CHECKLIST_RULES_PREVIEW_URL}?project_id=${projectId}`);
                commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW, result.data);
            } finally {
                commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRulesPreview', value: false });
            }
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CHECKLIST_RULES]: async ({ commit, dispatch, state }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            const result = await axios.get(CHECKLIST_RULES_URL);
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CHECKLIST_RULES, result.data);
        } finally {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
            dispatch(BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW, {
                projectId: state[BOLIGMAPPA_STORE.STATE.SELECTED_PROJECT],
            });
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.CREATE_BOLIGMAPPA_CHECKLIST_RULE]: async ({ commit }, rule) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.post(CHECKLIST_RULES_URL, rule);
        } catch (err) {
            logThrowApiError(err, CHECKLIST_RULES_URL, rule);
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.UPDATE_BOLIGMAPPA_CHECKLIST_RULE]: async ({ commit }, { id, ...rule }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.put(`${CHECKLIST_RULES_URL}/${id}`, { id, ...rule });
        } catch (err) {
            logThrowApiError(err, `${CHECKLIST_RULES_URL}/${id}`, { id, ...rule });
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.DELETE_BOLIGMAPPA_CHECKLIST_RULE]: async ({ commit }, { id }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.delete(`${CHECKLIST_RULES_URL}/${id}`);
        } catch (err) {
            logThrowApiError(err, `${CHECKLIST_RULES_URL}/${id}`);
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.SEND_ALL_CHECKLISTS_TOGGLE]: async ({ commit, state }, toggle) => {
        if (state[BOLIGMAPPA_STORE.STATE.LOADING].checklistsRules) {
            return;
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            const result = await axios.put(`${BOLIGMAPPA_INTEGRATION_URL}/settings`, { sendAllChecklists: toggle });
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_INTEGRATION, result.data);
        } catch (err) {
            logThrowApiError(err, `${BOLIGMAPPA_INTEGRATION_URL}/settings`, { sendAllChecklists: toggle });
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },

    [BOLIGMAPPA_STORE.ACTIONS.SEND_ALL_CUSTOM_FORMS_TOGGLE]: async ({ commit, state }, toggle) => {
        if (state[BOLIGMAPPA_STORE.STATE.LOADING].customFormRules) {
            return;
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: true });

        try {
            const result = await axios.put(`${BOLIGMAPPA_INTEGRATION_URL}/settings`, { sendAllCustomForms: toggle });
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_INTEGRATION, result.data);
        } catch (err) {
            logThrowApiError(err, `${BOLIGMAPPA_INTEGRATION_URL}/settings`, { sendAllCustomForms: toggle });
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW]: async ({ commit }, { projectId }) => {
        if (projectId > 0) {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormsRulesPreview', value: true });
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_SELECTED_PROJECT, projectId);

            try {
                const result = await axios.get(`${CUSTOM_FORMS_RULES_PREVIEW_URL}?project_id=${projectId}`);
                commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW, result.data);
            } finally {
                commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormsRulesPreview', value: false });
            }
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES]: async ({ commit, dispatch, state }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: true });

        try {
            const result = await axios.get(CUSTOM_FORMS_RULES_URL);
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CUSTOM_FORMS_RULES, result.data);
        } finally {
            commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: false });
            dispatch(BOLIGMAPPA_STORE.ACTIONS.FETCH_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW, {
                projectId: state[BOLIGMAPPA_STORE.STATE.SELECTED_PROJECT],
            });
        }
    },
    [BOLIGMAPPA_STORE.ACTIONS.CREATE_BOLIGMAPPA_CUSTOM_FORMS_RULE]: async ({ commit }, rule) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: true });

        try {
            await axios.post(CUSTOM_FORMS_RULES_URL, rule);
        } catch (err) {
            logThrowApiError(err, CUSTOM_FORMS_RULES_URL, rule);
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.UPDATE_BOLIGMAPPA_CUSTOM_FORMS_RULE]: async ({ commit }, { id, ...rule }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: true });

        try {
            await axios.put(`${CUSTOM_FORMS_RULES_URL}/${id}`, { id, ...rule });
        } catch (err) {
            logThrowApiError(err, `${CUSTOM_FORMS_RULES_URL}/${id}`, { id, ...rule });
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: false });
    },
    [BOLIGMAPPA_STORE.ACTIONS.DELETE_BOLIGMAPPA_CUSTOM_FORMS_RULE]: async ({ commit }, { id }) => {
        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: true });

        try {
            await axios.delete(`${CUSTOM_FORMS_RULES_URL}/${id}`);
        } catch (err) {
            logThrowApiError(err, `${CUSTOM_FORMS_RULES_URL}/${id}`);
        }

        commit(BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING, { key: 'customFormRules', value: false });
    },
};

const mutations = {
    [BOLIGMAPPA_STORE.MUTATIONS.SET_SELECTED_PROJECT]: (state, project) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.SELECTED_PROJECT, project);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_INTEGRATION]: (state, integration) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_INTEGRATION, integration);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_AUTHORIZATION_URL]: (state, integration) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_AUTHORIZATION_URL, integration);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_LOADING]: (state, { key, value }) => {
        Vue.set(state[BOLIGMAPPA_STORE.STATE.LOADING], key, value);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CHECKLIST_RULES]: (state, rules) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CHECKLIST_RULES, rules);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CHECKLIST_RULES_PREVIEW]: (state, preview) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CHECKLIST_RULES_PREVIEW, preview);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CUSTOM_FORMS_RULES]: (state, rules) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CUSTOM_FORMS_RULES, rules);
    },
    [BOLIGMAPPA_STORE.MUTATIONS.SET_BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW]: (state, preview) => {
        Vue.set(state, BOLIGMAPPA_STORE.STATE.BOLIGMAPPA_CUSTOM_FORMS_RULES_PREVIEW, preview);
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
