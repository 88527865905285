import Vue from 'vue';
import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';

export const namespace = 'cordel';
const CHECKLIST_RULES_URL = '/api/integrations/cordel/checklists/rules';
const CHECKLIST_RULES_PREVIEW_URL = '/api/integrations/cordel/checklists/rules/preview';
const CORDEL_INTEGRATION_URL = '/api/integrations/cordel';

export const CORDEL_STORE = {
    STATE: {
        SELECTED_PROJECT: 'SELECTED_PROJECT',
        CORDEL_INTEGRATION: 'CORDEL_INTEGRATION',
        CORDEL_CHECKLIST_RULES: 'CORDEL_CHECKLIST_RULES',
        CORDEL_CHECKLIST_RULES_PREVIEW: 'CORDEL_CHECKLIST_RULES_PREVIEW',
        LOADING: 'LOADING',
    },
    ACTIONS: {
        FETCH_CORDEL_INTEGRATION: 'FETCH_CORDEL_INTEGRATION',
        CREATE_CORDEL_INTEGRATION: 'CREATE_CORDEL_INTEGRATION',
        FETCH_CORDEL_CHECKLIST_RULES: 'FETCH_CORDEL_CHECKLIST_RULES',
        CREATE_CORDEL_CHECKLIST_RULE: 'CREATE_CORDEL_CHECKLIST_RULE',
        UPDATE_CORDEL_CHECKLIST_RULE: 'UPDATE_CORDEL_CHECKLIST_RULE',
        DELETE_CORDEL_CHECKLIST_RULE: 'DELETE_CORDEL_CHECKLIST_RULE',
        SEND_ALL_CHECKLISTS_TOGGLE: 'SEND_ALL_CHECKLISTS_TOGGLE',
        FETCH_CORDEL_CHECKLIST_RULES_PREVIEW: 'FETCH_CORDEL_CHECKLIST_RULES_PREVIEW',
    },
    MUTATIONS: {
        SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
        SET_CORDEL_INTEGRATION: 'SET_CORDEL_INTEGRATION',
        SET_CORDEL_CHECKLIST_RULES: 'SET_CORDEL_CHECKLIST_RULES',
        SET_CORDEL_CHECKLIST_RULES_PREVIEW: 'SET_CORDEL_CHECKLIST_RULES_PREVIEW',
        SET_LOADING: 'SET_LOADING',
    },
};

function initialState() {
    return {
        [CORDEL_STORE.STATE.SELECTED_PROJECT]: -1,
        [CORDEL_STORE.STATE.CORDEL_INTEGRATION]: {},
        [CORDEL_STORE.STATE.CORDEL_CHECKLIST_RULES]: [],
        [CORDEL_STORE.STATE.CORDEL_CHECKLIST_RULES_PREVIEW]: [],
        [CORDEL_STORE.STATE.LOADING]: {
            checklistsRules: false,
            checklistsRulesPreview: false,
            cordelIntegration: false,
        },
    };
}

const actions = {
    [CORDEL_STORE.ACTIONS.CREATE_CORDEL_INTEGRATION]: async ({ commit, dispatch }, integration) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'cordelIntegration', value: true });

        try {
            await axios.post(CORDEL_INTEGRATION_URL, integration);
        } catch (e) {
            logThrowApiError(e);
        } finally {
            commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'cordelIntegration', value: false });
            dispatch(CORDEL_STORE.ACTIONS.FETCH_CORDEL_INTEGRATION);
        }
    },
    [CORDEL_STORE.ACTIONS.FETCH_CORDEL_INTEGRATION]: async ({ commit }) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'cordelIntegration', value: true });

        try {
            const result = await axios.get(CORDEL_INTEGRATION_URL);
            commit(CORDEL_STORE.MUTATIONS.SET_CORDEL_INTEGRATION, result.data);
        } finally {
            commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'cordelIntegration', value: false });
        }
    },
    [CORDEL_STORE.ACTIONS.FETCH_CORDEL_CHECKLIST_RULES_PREVIEW]: async ({ commit }, { projectId }) => {
        if (projectId > 0) {
            commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRulesPreview', value: true });
            commit(CORDEL_STORE.MUTATIONS.SET_SELECTED_PROJECT, projectId);

            try {
                const result = await axios.get(`${CHECKLIST_RULES_PREVIEW_URL}?project_id=${projectId}`);
                commit(CORDEL_STORE.MUTATIONS.SET_CORDEL_CHECKLIST_RULES_PREVIEW, result.data);
            } finally {
                commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRulesPreview', value: false });
            }
        }
    },
    [CORDEL_STORE.ACTIONS.FETCH_CORDEL_CHECKLIST_RULES]: async ({ commit, dispatch, state }) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            const result = await axios.get(CHECKLIST_RULES_URL);
            commit(CORDEL_STORE.MUTATIONS.SET_CORDEL_CHECKLIST_RULES, result.data);
        } finally {
            commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
            dispatch(CORDEL_STORE.ACTIONS.FETCH_CORDEL_CHECKLIST_RULES_PREVIEW, {
                projectId: state[CORDEL_STORE.STATE.SELECTED_PROJECT],
            });
        }
    },
    [CORDEL_STORE.ACTIONS.CREATE_CORDEL_CHECKLIST_RULE]: async ({ commit }, rule) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.post(CHECKLIST_RULES_URL, rule);
        } catch (err) {
            logThrowApiError(err, CHECKLIST_RULES_URL, rule);
        }

        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [CORDEL_STORE.ACTIONS.UPDATE_CORDEL_CHECKLIST_RULE]: async ({ commit }, { id, ...rule }) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.put(`${CHECKLIST_RULES_URL}/${id}`, { id, ...rule });
        } catch (err) {
            logThrowApiError(err, `${CHECKLIST_RULES_URL}/${id}`, { id, ...rule });
        }

        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [CORDEL_STORE.ACTIONS.DELETE_CORDEL_CHECKLIST_RULE]: async ({ commit }, { id }) => {
        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            await axios.delete(`${CHECKLIST_RULES_URL}/${id}`);
        } catch (err) {
            logThrowApiError(err, `${CHECKLIST_RULES_URL}/${id}`);
        }

        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
    [CORDEL_STORE.ACTIONS.SEND_ALL_CHECKLISTS_TOGGLE]: async ({ commit, state }, toggle) => {
        if (state[CORDEL_STORE.STATE.LOADING].checklistsRules) {
            return;
        }

        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: true });

        try {
            const result = await axios.put(`${CORDEL_INTEGRATION_URL}/settings`, { sendAllChecklists: toggle });
            commit(CORDEL_STORE.MUTATIONS.SET_CORDEL_INTEGRATION, result.data);
        } catch (err) {
            logThrowApiError(err, `${CORDEL_INTEGRATION_URL}/settings`, { sendAllChecklists: toggle });
        }

        commit(CORDEL_STORE.MUTATIONS.SET_LOADING, { key: 'checklistsRules', value: false });
    },
};

const mutations = {
    [CORDEL_STORE.MUTATIONS.SET_SELECTED_PROJECT]: (state, project) => {
        Vue.set(state, CORDEL_STORE.STATE.SELECTED_PROJECT, project);
    },
    [CORDEL_STORE.MUTATIONS.SET_CORDEL_INTEGRATION]: (state, integration) => {
        Vue.set(state, CORDEL_STORE.STATE.CORDEL_INTEGRATION, integration);
    },
    [CORDEL_STORE.MUTATIONS.SET_LOADING]: (state, { key, value }) => {
        Vue.set(state[CORDEL_STORE.STATE.LOADING], key, value);
    },
    [CORDEL_STORE.MUTATIONS.SET_CORDEL_CHECKLIST_RULES]: (state, rules) => {
        Vue.set(state, CORDEL_STORE.STATE.CORDEL_CHECKLIST_RULES, rules);
    },
    [CORDEL_STORE.MUTATIONS.SET_CORDEL_CHECKLIST_RULES_PREVIEW]: (state, preview) => {
        Vue.set(state, CORDEL_STORE.STATE.CORDEL_CHECKLIST_RULES_PREVIEW, preview);
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
